/* Embed the client portal in other sites and workaround browser limitations. 
* 
* BASIC USAGE: Add the script right after the IFRAME embed. Make sure the IFRAME has id='powerdiary_portal'
* 
*       <iframe src="https://clientportal.powerdiary.com/clientportal/xxxxxxxx" 
*               id="powerdiary_portal" 
*               height="500px" 
*               width="500px" 
*               border="none"></iframe>
*       <script src="https://clientportal.powerdiary.com/dist/portalEmbed.js"></script>
* 
* ADVANCED OPTIONS: 
*       Specify settings in the window variable, prefixing them with 'PD_'. Enter these options
*       in a <script></script> before linking the script.
*       For example:
*           <iframe id='embed' ...></iframe>
*           <script>
*               window.PD_IFRAME_ID = 'embed';
*           </script>
*           <script src="https://clientportal.powerdiary.com/dist/portalEmbed.js"></script>
* 
* */

class PortalEmbedParent {

    private iframe: HTMLIFrameElement = null;
    private defaultSettings: { [id: string]: any } = {
        'FALLBACK_ELEMENT_ID': 'powerdiary_fallback_btn',
        'IFRAME_ID': 'powerdiary_portal',
        'LOAD_TIMEOUT': 3000,
        'BUTTON_TEMPLATE': '<a href="{{url}}" style="{{styles}}">Book Now &gt;</a>',
        'BUTTON_TEMPLATE_STYLES': 'background: #0980fd;color: #fff;padding: 11px 40px;border-radius: 5px;margin: 5px 10px;display: inline-block;text-decoration: none;font-size: 1.2em;font-family: \'Roboto\',sans-serif;display: block;width: 150px;text-align: center;margin: 0 auto;white-space: nowrap;box-sizing: content-box;',
        'FALLBACK_ELEMENT_DISPLAY': 'block'
    }

    monitorIframeLoad() {
        const iframeId: string = this.getSettingValue('IFRAME_ID');
        let loadTimeout: number = this.getSettingValue('LOAD_TIMEOUT');
        if (loadTimeout <= 0) loadTimeout = 1;

        this.iframe = document.getElementById(iframeId) as HTMLIFrameElement;
        if (!this.iframe) {
            console.error(`PowerDiary: iframe with id=${iframeId} not found.`);
            return;
        }
        
        //this.iframe.addEventListener('load', this.handleIframeLoaded.bind(this));
        window.addEventListener( 'message', (evt) => {
            if (evt.data === 'ClientPortalLoading') this.handleIframeLoaded();
        });
        

        this.renderFallbackButtonHidden(this.iframe);

        (window as any).clientPortalTimeout = setTimeout(function () {
            PortalEmbedParent.log("iframe not loaded. Rendering fallback method.");
            this.toggleFallbackMethod(true);
        }.bind(this), loadTimeout);
    }

    private getSettingValue(key: string): any {
        const userValue = (window as any)["PD_" + key];
        if (typeof (userValue) !== "undefined") return userValue;
        return this.defaultSettings[key];
    }

    private renderFallbackButtonHidden(iframe: HTMLElement) {
        const fallbackElementId: string = this.getSettingValue('FALLBACK_ELEMENT_ID');
        const styles = this.getSettingValue("BUTTON_TEMPLATE_STYLES");
        let buttonTemplate = this.getSettingValue('BUTTON_TEMPLATE');
        
        buttonTemplate = buttonTemplate
            .replace('{{url}}', iframe.getAttribute("src"))
            .replace('{{styles}}', styles);

        const button = PortalEmbedParent.createElementFromHTML(buttonTemplate);
        button.style.display = "none";
        button.id = fallbackElementId;
        iframe.parentNode.insertBefore(button, iframe.nextSibling);
    }
    
    private toggleFallbackMethod(show: boolean) {
        const fallbackElementId: string = this.getSettingValue('FALLBACK_ELEMENT_ID');
        const fallbackElementDisplay: string = this.getSettingValue('FALLBACK_ELEMENT_DISPLAY');
        
        // Show/hide fallback method
        const element = document.getElementById(fallbackElementId);
        element.style.display = show ? fallbackElementDisplay : "none";
        
        // Show/hide iframe (!show)
        this.iframe.style.display = show ? "none" : "block";
    }

    private static createElementFromHTML(html: string): HTMLElement {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.firstChild as HTMLElement;
    }

    private handleIframeLoaded() {
        PortalEmbedParent.log("iframe loaded");
        
        const win = (window as any)
        win.clientPortalLoaded = true;
        clearTimeout(win.clientPortalTimeout);
        
        this.toggleFallbackMethod(false);
    }
    
    private static log(msg: string) {
        console.log(`PowerDiary: ${msg}`);
    }

}

new PortalEmbedParent().monitorIframeLoad();
